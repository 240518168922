<template>
<div class="extended_check" :class="theModel.checked ? 'active' : ''">
    <div class="row gy-y justify-content-between align-items-center">
        <div class="col">
            <div class="form-check mb-3 mb-md-0">
                <input class="form-check-input ml-2" type="checkbox" value="1" :id="'relay_'+theModel.distance_id" :checked="theModel.quantity > 0" @change="changeChecked">
                <label class="form-check-label ps-3" :for="'relay_'+theModel.distance_id">
                    <p v-if="relayName" class="fw-bold text_20 mb-1">{{ relayName }}</p>
                    <p v-if="item.age_name" class="mb-0">{{item.age_name}}</p>
                </label>
            </div>
        </div>
        <div class="ps-5 ps-md-0 col-md-3">
            <div v-if="theModel.quantity > 0" class="input-group input-group-num">
                <button @click.prevent="theModel.quantity = theModel.quantity - 1" :disabled="theModel.quantity == 0" type="button">-</button>
                <input type="number" class="form-control" min="0" v-model="theModel.quantity">
                <button @click.prevent="theModel.quantity = theModel.quantity + 1" type="button">+</button>
            </div>
            <a v-else @click.prevent="theModel.quantity = 1" href="#" class="btn btn-primary btn-primary-shadow btn-rounded w-100">Добавить</a>
        </div>
    </div>
</div>
</template>

<script>
import { computed } from 'vue';
export default {
    props: {
        item: {
            type: Object,
            default: [],
        },
        modelValue: {
            type: Object,
            default: () => ({}),
        },
    },
    methods:{
        changeChecked(e){
            console.log(e.target.checked)
            if(e.target.checked){
                this.theModel.quantity = 1
            } else {
                this.theModel.quantity = 0
            }
        }
    },
    computed: {
        relayName(){
            if(this.item.swimstyle_name) return this.item.swimstyle_name
            let text = ''
            if(this.item.distance){
                text += this.item.distance + 'м, '
            }
            if(this.item.stroke_name){
                text += this.item.stroke_name
            }
            return text
        }
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const theModel = computed({  
            get: () => props.modelValue,
            set: (value) => emit('update:modelValue', value),
        });
        return { theModel };
    },
}
</script>

<style>

</style>
