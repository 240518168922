<template>
	<default-layout>
		<template #content>
			<page-title title="Мой аккаунт" />

			<div class="page_section">
				<div class="container mb-4">
					<div class="row gx-3">
						<div class="col-auto mb-3 mb-md-0" v-for="item in this.tabs" :key="item.id">
							<a
								@click.prevent="$router.push({ query: { tab: item.name } })"
								href="#"
								class="btn btn-white btn-rounded px-3 px-md-4"
								:class="!$route.query.tab && item.id == 0 || $route.query.tab === item.name ? 'active' : ''">
								<span class="text-primary">
									<vue-feather :type="item.icon"></vue-feather>
								</span>
								{{ item.name }}
							</a>
						</div>
					</div>
				</div>

				<div
					class="container"
					v-if="!$route.query.tab || $route.query.tab == 'Мои заявки'">
					<ProfileMyRequestsTab :key="requests_tab_key" />
				</div>

				<!-- <div
					class="container"
					v-if="$route.query.tab == 'Мои заказы'">
					<ProfileShopOrdersTab :key="shop_orders_tab_key" />
				</div> -->

				<div class="container" v-if="$route.query.tab == 'Спортсмены'">
					<ProfileAthletesTab
						:key="athletes_tab_key"
						@changeData="athletes_tab_key += 1" />
				</div>

				<div class="container" v-if="$route.query.tab == 'Клубы'">
					<ProfileClubsTab :key="clubs_tab_key" />
				</div>

				<!-- <div class="container" v-if="$route.query.tab == 'Мои фото'">
					<ProfilePhotobankTab :key="photo_tab_key" />
				</div> -->

				<div class="container" v-if="$route.query.tab == 'Учетные данные'">
					<div class="row g-4">
						<div class="col-lg-4">
							<ProfileChangeAvatar />
						</div>

						<div class="col-lg-4">
							<ProfileChangeAccountData />
						</div>

						<div class="col-lg-4">
							<ProfileChangePassword />
						</div>
					</div>

					<div class="mt-4 text-end">
						<SwalButton
							class="small text-danger"
							title="Вы действительно хотите удалить свой аккаунт?"
							text="Это действие нельзя будет отменить"
							icon="warning"
							confirmButtonText="Удалить"
							confirmButtonColor="#EA5455"
							successText="Аккаунт удален"
							:callback="deleteProfile">
							Удалить аккаунт
						</SwalButton>
					</div>
				</div>
			</div>
		</template>
		<template #offcanvas>
			<EditAthlet />
		</template>
	</default-layout>
</template>

<script>
/* import ProfileShopOrdersTab from '../components/profile/ProfileShopOrdersTab' */
/* import ProfilePhotobankTab from '../components/profile/ProfilePhotobankTab' */
import ProfileChangePassword from '@/components/profile/ProfileChangePassword'
import ProfileChangeAccountData from '@/components/profile/ProfileChangeAccountData'
import ProfileChangeAvatar from '@/components/profile/ProfileChangeAvatar'
import ProfileClubsTab from '@/components/profile/ProfileClubsTab'
import ProfileAthletesTab from '@/components/profile/ProfileAthletesTab'
import ProfileMyRequestsTab from '@/components/profile/ProfileMyRequestsTab'
import EditAthlet from '../components/offcanvases/EditAthlet'
import SwalButton from '../components/SwalButton'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import PageTitle from '../components/PageTitle.vue'
import api from '@/store/api'
import { mapMutations } from 'vuex'
import setMeta from "@/views/seoMeta"
export default {
	components: {
    /* ProfileShopOrdersTab,
    ProfilePhotobankTab, */
		ProfileChangePassword,
		ProfileChangeAccountData,
		ProfileChangeAvatar,
		ProfileClubsTab,
		ProfileAthletesTab,
		ProfileMyRequestsTab,
		EditAthlet,
		SwalButton,
		PageTitle,
		DefaultLayout,
	},
	data() {
		return {
			activeTab: 0,
			requests_tab_key: 0,
			shop_orders_tab_key: 0,
			athletes_tab_key: 0,
			clubs_tab_key: 0,
			photo_tab_key: 0,
			tabs: [
				{
					id: 0,
					name: 'Мои заявки',
					icon: 'list',
					isActive: true,
				},
				/* {
					id: 1,
					name: 'Мои заказы',
					icon: 'shopping-cart',
					isActive: false,
				}, */
				{
					id: 2,
					name: 'Спортсмены',
					icon: 'user',
					isActive: false,
				},
				{
					id: 3,
					name: 'Клубы',
					icon: 'bookmark',
					isActive: false,
				},
				/* {
					id: 4,
					name: 'Мои фото',
					icon: 'camera',
					isActive: false,
				}, */
				{
					id: 5,
					name: 'Учетные данные',
					icon: 'lock',
					isActive: false,
				},
			],
		}
	},
	mounted(){
		setMeta(
            'Мой аккаунт | Swimtech.ru', 
            null, 
            null,
            false
        )
	},
	methods: {
		...mapMutations({
			toggleOffcanvas: 'global/toggleOffcanvas',
		}),
		deleteProfile() {
			api
				.delete(process.env.VUE_APP_BASE_URL + 'account/delete')
				.then((res) => {
					if (res && res.data.success) {
						localStorage.removeItem('access_token')
						this.$router.push({ name: 'login' })
					} else {
						this.$swal('Неизвестная ошибка', '', 'warning')
					}
				})
				.catch((error) => {
					console.log(error)
					this.error_message = error.response.data.message
					this.$swal(error.response.data.message, '', 'danger')
				})
		},
	},
}
</script>

<style scoped>
.dropdown-menu {
	min-width: 150px;
}

.dropdown-toggle:after,
.dropdown-toggle:before {
	display: none;
}

.dropdown-menu a {
	text-decoration: none;
	color: #071031;
}
</style>