<template>
<div class="athlet_box h-100 d-flex flex-column justify-content-between">
    <div class="athlet_box__top">
        <div class="athlet_box__top__overlay"></div>
        <div class="athlet_box__top__content position-relative">
            <p class="text_20 text-muted ">{{name}}</p>
            <div class="athlet_box__avatar_container">
                <Avatar :src="item.avatar" :name="item.athlete_name" :size="'lg'" />
            </div>
            <img src="/assets/img/flag.svg" class="athlet_box__flag rounded-circle">
            <p class="text-muted small text-uppercase mb-0">{{item.club_nation}}, {{item.club_code}}</p>
        </div>
    </div>
    <div class="athlet_box__content p-2 text-center h-100 d-flex flex-column justify-content-between">
        <div class="top">
            <p class="fw-bold text_20 mb-0">{{item.athlete_name}}</p>
            <p class="fw-700 small text-muted mb-2">{{item.club_name}}</p>
        </div>
        <div class="bottom">
            <p class="text-decorative text-warning fw-bold fs-1 mb-2">{{item.swimtime.slice(3)}}</p>
            <p class="text-muted small mb-0">{{item.meet_name}}</p>
            <p class="text-muted small mb-0">{{item.session_date}}</p>
        </div>
    </div>
</div>
</template>

<script>
import Avatar from '../UI/Avatar'
export default {
  components: { Avatar },
    props: ['item', 'name']
}
</script>

<style>

</style>
