<template>
<header :class="this.$store.getters['global/getBackgroundStatus'] ? 'blured' : ''">
    <div class="header__top pt-3 pb-3">
        <div class="container d-flex align-items-center">
            <a href="#" @click="toggleOffcanvas('mobile_menu')" class="mobile_menu__trigger d-lg-none me-3 text-dark">
                <vue-feather type="menu"></vue-feather>
            </a>
            <div class="header_brand d-flex align-items-center me-auto">
                <router-link to="/" class="header_brand__logo d-block me-3">
                    <img src="/assets/img/vfp-logo.svg" alt="АНО Дирекция Мероприятий Всероссийской Федерации Плавания" />
                </router-link>
                <p class="header_brand__desc small text-muted mb-0 d-none d-lg-block">
                    АНО "Дирекция Мероприятий Всероссийской Федерации Плавания"
                </p>
            </div>
            <header-tools></header-tools>
        </div>
    </div>
    <header-navbar />
    <div class="header_backdrop"></div>
</header>
</template>

<script>
import HeaderNavbar from '@/components/header/HeaderNavbar.vue'
import HeaderTools from '@/components/header/HeaderTools.vue';
import {
    mapMutations
} from "vuex";
export default {
    name: "Header",
    components: {
        HeaderNavbar,
        HeaderTools,
    },
    methods: {
        ...mapMutations({
            toggleOffcanvas: "global/toggleOffcanvas",
        }),
    }
};
</script>

<style>
header.blured {
    filter: blur(10px);
}
</style>
