<template>
<PageLoader v-if="!is_loaded" />
<with-sidebar-layout v-if="is_loaded">
    <template #head>
        <PageTitle title="Заявка" :breadcrumbs="breadcrumbs"/>
    </template>
    <template #content>
        <OrderResultMessage 
            v-if="show_order_message"
            :status="orderdata.payment_status"
            class="mb-4" />
        <div class="content_box p-4 mb-4">
            <div class="content_box__header d-flex justify-content-between align-items-center mb-3 position-relative z-2">
                <h2 class="title_block mb-2 fs-3">Заявка #{{orderdata.id}}</h2>
            </div>
            <div class="row g-4">
                <div v-if="postData.name && postData.id" class="col-auto small">
                    <p class="text-muted mb-1">Мероприятие</p>
                    <router-link :to="`/stage/${postData.id}`" class="mb-0 fw-bold">
                    {{postData.name}}
                </router-link>
                </div>
                <div v-if="orderdata.sent_date" class="col-auto small">
                    <p class="text-muted mb-1">Дата заявки</p>
                    <p class="mb-0">{{orderdata.sent_date}}</p>
                </div>
                <div v-if="postData.location_address" class="col-auto small ">
                    <p class="text-muted mb-1">Место проведения</p>
                    <p class="mb-0">
                        {{postData.location_address}}
                    </p>
                </div>
                <div v-if="postData.dates" class="col-auto small">
                    <p class="text-muted mb-1">Сроки проведения</p>
                    <p class="mb-0">
                        {{postData.dates}}
                    </p>
                </div>
            </div>
        </div>
        <SportsmensTable 
            :items="form_data.distances"
            :distances="true"
            :documents="true"
        />
        <EstafetasTable 
            v-if="form_data.relay_races.length"
            :items="form_data.relay_races"
        />
        <AdditionalData 
            :club="{
                name: form_data.club_name,
                shortname: form_data.club_shortname,
                state: form_data.club_state,
            }"
            :contacts="{
                name: form_data.agent_name,
                phone: form_data.agent_phone,
                email: form_data.agent_email,
            }"
        />

        <p class="text-decorative fs-2 text-uppercase mb-2">Информация о заказе</p>
        <div class="row g-4">
            <div class="col-md-6">
                <OrdersList 
                    id="order_list"
                    :entry_fee="orderdata.entry_fee"
                    :distances="form_data.distances"
                    :relay_races="form_data.relay_races"
                    :extra_services="form_data.extra_services"
                    :postdata="postData" 
                    :already_paid="orderdata.already_paid"
                />
            </div>

            <div class="col-md-6">
                <RequestOrderInfo 
                    v-if="postData.first_status == 'pending_payment'"
                    :id="orderdata.id"
                    :payment_status="this.form_data.payment_status"
                    :summ="orderdata.summ"
                    :payment_summ="paymentSumm"
                    :paytime_end="orderdata.paytime_end"
                    @send="toPayment()"
                    @time_end="time_end()"
                />
                <div v-if="orderdata.payers_name || orderdata.payers_phone || orderdata.payers_email" class="content_box p-4 mb-4">
                    <div class="content_box__header d-flex justify-content-between align-items-center mb-3 position-relative z-2">
                        <h2 class="title_block mb-2 text-muted">Плательщик</h2>
                    </div>
                    <table class="list_box__content">
                        <tr v-if="orderdata.payers_name">
                            <td class="text-muted">ФИО:</td>
                            <td class="ps-3">{{orderdata.payers_name}}</td>
                        </tr>
                        <tr v-if="orderdata.payers_phone">
                            <td class="text-muted">Телефон:</td>
                            <td class="ps-3">{{orderdata.payers_phone}}</td>
                        </tr>
                        <tr v-if="orderdata.payers_email">
                            <td class="text-muted">Email:</td>
                            <td class="ps-3">{{orderdata.payers_email}}</td>
                        </tr>
                    </table>
                </div>
                <div v-if="payment_method_desc" class="content_box p-4 mb-4">
                    <div class="content_box__header d-flex justify-content-between align-items-center mb-3 position-relative z-2">
                        <h2 class="title_block mb-2 text-muted">Способ оплаты</h2>
                    </div>
                    <p class="fw-bold mb-1">{{payment_method_desc.title}}</p>
                    <p class="text-muted small mb-0">{{payment_method_desc.desc}}</p>
                </div>
            </div>

        </div>
    </template>
    <template #sidebar>
        <RequestSidebarBox 
            :payment_status="this.form_data.status"
            :first_status="postData.first_status"
            :summ="paymentSumm"
            :paytime_end="orderdata.paytime_end"
            @send="toPayment()"
            @time_end="time_end()"
        />
    </template>
</with-sidebar-layout>
</template>

<script>
import PageLoader from '../components/PageLoader.vue'
import OrderResultMessage from '../components/OrderResultMessage'
import PageTitle from '../components/PageTitle.vue'
import PageAlertBox from '../components/PageAlertBox.vue'
import RequestOrderInfo from '../components/RequestOrderInfo.vue'
import OrdersList from '../components/request/OrdersList.vue'
import SportsmensTable from '@/components/request/SportsmensTable.vue'
import RequestSidebarBox from '../components/RequestSidebarBox.vue'
import WithSidebarLayout from '../layouts/WithSidebarLayout.vue'
import EstafetasTable from '@/components/request/EstafetasTable.vue'
import AdditionalData from '@/components/request/AdditionalData.vue'

import {
    mapGetters, mapActions
} from "vuex"
export default {
    props: {
        postData: {
            type: Object,
            default: {}
        },
        orderdata: {
            type: Object,
            default: {}
        },
    },
    data(){
        return {
            form_data: {
                id: null,
                status: null,
                payment_status: null,
                type: 'competition',
                event_id: null,
                event_child_id: null,
                club_name: null,
                club_shortname: null,
                club_state: null,
                agent_name: null,
                agent_phone: null,
                agent_email: null,
                entry_fee: null,
                payers_name: null,
                payers_phone: null,
                payers_email: null,
                distances: [
                    {
                        distances: [
                            {
                                name: null,
                                key: 0,
                                id: null,
                                entrytime: null,
                                system_entrytime: false
                            }
                        ],
                        athlete: null,
                        athlete_id: null,
                        online_request: false,
                        athlete_documents: []
                    }
                ],
                relay_races: [],
                extra_services: [],
            },
            payment_method_desc: null,
            breadcrumbs: [{
                title: 'Мой аккаунт',
                link: '/profile'
            }],
            show_order_message: false,
            is_loaded: false
        }
    },
    computed: {
        ...mapGetters({
            getAthleteByID: "athlets/getAthleteByID",
            payment_methods: 'global/getPaymentMethods',
        }),
        paymentSumm(){
            if(this.orderdata.already_paid){
                return this.orderdata.summ - this.orderdata.already_paid
            }
            return this.orderdata.summ
        },
        athleteIds(){
			return this.form_data.distances.map(item => {
				return item.athlete_id
			})
		}
    },
    async mounted(){
        //await this.fetchAthletes()
        //await this.fetchAthletesByIds()
        this.transformOrderData()
        await this.fetchAthletesByIds(this.athleteIds)
        this.insertAthletesInfo()
        this.addDistancesPrice()
        await this.checkPaymentStatus()
        this.is_loaded = true
    },
    methods: {
        ...mapActions({
            fetchAthletes: "profile/fetchAthletes",
            fetchAthletesByIds: "athlets/fetchAthletesByIds",
            fetchAthletesAll: "athlets/fetchAthletes",
            fetchPaymentStatus: 'order/fetchPaymentStatus',
            getPaymentUrl: 'order/getPaymentUrl',
        }),
        addDistancesPrice(){
			this.form_data.distances = this.form_data.distances.map(distance_obj => {
				distance_obj.distances = distance_obj.distances.map(distance => {
					if(distance.id){
						if(distance_obj.distances.length > 1){
							distance.count = 'many'
						} else {
							distance.count = 'one'
						}
                        let post_distance = this.postData.distances.find(item => item.id == distance.id) 
                        if(post_distance) {
                            distance.distance = post_distance.distance
                        }
						distance.price = parseInt(this.postData.distance_prices[distance.distance][distance.count])
					}
					return distance
				})
				return distance_obj
			})
		},
        transformOrderData(){
            if(this.orderdata){
                
                this.form_data.status = this.orderdata.status
                this.form_data.payment_status = this.orderdata.payment_status
                this.form_data.id = this.orderdata.id
                this.form_data.type = this.orderdata.type
                this.form_data.event_id = this.orderdata.event_id
                this.form_data.event_child_id = this.orderdata.event_stage_id
                this.form_data.club_name = this.orderdata.club_name
                this.form_data.club_shortname = this.orderdata.club_shortname
                this.form_data.club_state = this.orderdata.club_state
                this.form_data.agent_name = this.orderdata.agent_name
                this.form_data.agent_phone = this.orderdata.agent_phone
                this.form_data.agent_email = this.orderdata.agent_email
                this.payment_method_desc = this.payment_methods.find(item => item.id == this.orderdata.payment_method)
                if(this.orderdata.items && this.orderdata.items.length){
                    this.form_data.distances = this.orderdata.items.filter(item => item.type == 'distance')
                    this.form_data.relay_races = this.orderdata.items.filter(item => item.type == 'relay_race')
                }

                if(this.orderdata.extra_services && this.orderdata.extra_services.length > 0){
                    let extra_services_array = []
                    this.orderdata.extra_services.forEach((item, i) => {
                        let extra_service = {
                            id: item.id,
                            quantity: item.quantity,
                            name: item.name,
                            price: item.price,
                            distances: []
                        }
                        if(!_.isEmpty(item.athletes)){
                            item.athletes.forEach(athlete => {
                                let athlete_item = {
                                    athlete_id: athlete.athlete_id,
                                    distances: []
                                }
                                if(!_.isEmpty(athlete.distances)){
                                    athlete.distances.forEach(distance_id => {
                                        athlete_item.distances.push(distance_id.id)
                                    })
                                }
                                extra_service.distances.push(athlete_item)
                            })
                        }
                        extra_services_array.push(extra_service)
                    })
                    if(!_.isEmpty(extra_services_array)) this.form_data.extra_services = extra_services_array
                }
            }
        },
        insertAthletesInfo(){
            this.form_data.distances.forEach(item => {
                if(item.athlete_id){
                    item.athlete = this.getAthleteByID(item.athlete_id)
                }
            })
        },
        async toPayment(){
            this.$swal({
                title: 'Перенаправление на форму оплаты',
                text: 'Пожалуйста, не закрывайте страницу',
                icon: 'info',
                allowOutsideClick: false,
                allowEnterKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                didOpen: () => {
                    this.$swal.showLoading()
                },
            })
			let res = await this.getPaymentUrl(this.orderdata.id)
			if (res?.url) {
				window.location.href = res.url
			} else {
				this.$swal.close()
				this.$swal('Возникла неизвестная ошибка, попробуйте еще раз', '', 'error')
			}
        },
        time_end(){
            this.form_data.status = 'rejected'
            this.form_data.payment_status = 'rejected'
        },
        async checkPaymentStatus(){
            if(this.postData.first_status == 'pending_payment' && this.form_data.payment_status == 'pending'){
                let res = await this.fetchPaymentStatus(this.form_data.id)
                this.form_data.status = this.orderdata.status
                this.form_data.payment_status = this.orderdata.payment_status
                this.show_order_message = true
            }
        },
    },
    components: {
        OrderResultMessage,
        WithSidebarLayout,
        RequestSidebarBox,
        SportsmensTable,
        EstafetasTable,
        AdditionalData,
        OrdersList,
        RequestOrderInfo, PageAlertBox, PageTitle,
        PageLoader
    },
}
</script>

<style>
</style>
