import api from "./api"

export const profileStateModule = {
	state: () => ({
		userdata: null,
		orders: [],
		athletes: [],
		athlete_names: null,
		clubs: [],
		notifications: [],
		shop_orders: [],
	}),
	getters: {
		getUserData(state) {
			return state.userdata
		},
		getOrders(state) {
			return state.orders
		},
		getShopOrders(state) {
			return state.shop_orders
		},
		getAthletes(state) {
			return state.athletes
		},
		getAthleteNames(state) {
			return state.athlete_names
		},
		getClubs(state) {
			return state.clubs
		},
		getNotifications(state) {
			return state.notifications
		},
	},
	mutations: {
		setUser(state, response) {
			state.userdata = response
		},
		clearUser(state) {
			state.userdata = null
		},
		setOrders(state, response) {
			state.orders = response
		},
		setShopOrders(state, response) {
			state.shop_orders = response
		},
		setAthletes(state, response) {
			state.athletes = response
		},
		setAthleteNames(state, response) {
			state.athlete_names = response
		},
		changeAthlete(state, athlete) {
			let index = state.athletes.findIndex((item) => {
				return item.id === athlete.id
			})
			if (index > -1) {
				state.athletes[index] = athlete
			} else {
				state.athletes.push(athlete)
			}
		},
		removeAthlete(state, id) {
			let index = state.athletes.findIndex((item) => {
				return item.id === id
			})
			if (index > -1) {
				state.athletes.splice(index, 1)
			}
		},
		setClubs(state, response) {
			state.clubs = response
		},
		setNotifications(state, response) {
			state.notifications = response
		},
		setSubscribed(state, response) {
			if (state.userdata) state.userdata.is_subscribed = response
		},
	},
	actions: {
		async fetchUserData({ state, commit }) {
			try {
				const response = await api.get(process.env.VUE_APP_BASE_URL + 'user')
				commit('setUser', response.data)
			} catch (e) {
				console.log('Ошибка получения данных с сервера о пользователе')
			}
		},
		async fetchOrders({ state, commit }) {
			try {
				const response = await api.get(process.env.VUE_APP_BASE_URL + 'orders')
				commit('setOrders', response.data)
			} catch (e) {
				console.log('Ошибка получения данных с сервера о заказах')
			}
		},
		async fetchShopOrders({ state, commit }) {
			try {
				const response = await api.get(process.env.VUE_APP_BASE_URL + 'shop/orders')
				commit('setShopOrders', response.data)
			} catch (e) {
				console.log('Ошибка получения данных с сервера о заказах')
			}
		},
		async fetchAthletes({ state, commit }) {
			try {
				const response = await api.get(process.env.VUE_APP_BASE_URL + 'athletes')
				commit('setAthletes', response.data)
			} catch (e) {
				console.log('Ошибка получения данных с сервера о атлетах')
			}
		},
		async fetchAthleteNames({ state, commit }) {
			try {
				const response = await api.get(process.env.VUE_APP_BASE_URL + 'athletes/names')
				commit('setAthleteNames', response.data)
			} catch (e) {
				console.log('Ошибка получения данных с сервера о атлетах')
			}
		},
		async fetchClubs({ state, commit }) {
			try {
				const response = await api.get(process.env.VUE_APP_BASE_URL + 'clubs')
				commit('setClubs', response.data)
			} catch (e) {
				console.log('Ошибка получения данных с сервера о клубах')
			}
		},
		async fetchNotifications({ state, commit }) {
			try {
				const response = await api.get(process.env.VUE_APP_BASE_URL + 'notifications/get')
				commit('setNotifications', response.data)
			} catch (e) {
				console.log('Ошибка получения данных с сервера о клубах')
			}
		},
		async fetchNotificationsSetViewed({ state, commit }) {
			try {
				const response = await api.get(
					process.env.VUE_APP_BASE_URL + 'notifications/setviewed'
				)
				commit('setNotifications', response.data)
			} catch (e) {
				console.log('Ошибка получения данных с сервера о клубах')
			}
		},
		async fetchNotificationsDelete({ state, commit }) {
			try {
				const response = await api.delete(
					process.env.VUE_APP_BASE_URL + 'notifications/delete'
				)
				commit('setNotifications', response.data)
			} catch (e) {
				console.log('Ошибка получения данных с сервера о клубах')
			}
		},
		async fetchIsSubcribed({ state, commit }) {
			try {
				const response = await api.put(process.env.VUE_APP_BASE_URL + 'subscribed', {
					is_subscribed: 1,
				})
				commit('setSubscribed', response.data)
			} catch (e) {
				console.log('Ошибка получения данных о подписке')
			}
		},
        clearUser({ state, commit }) {
			commit('clearUser')
		},
	},
	namespaced: true,
}