import api from "./api"

export const orderStateModule = {
    state: () => ({
        order_data: null,
    }),
    getters: {
        getOrderData(state) {
            return state.order_data
        },
    },
    mutations: {
        setOrderData(state, response) {
            state.order_data = response
        },
        setOrderPaymentStatus(state, response) {
            state.order_data.status = response.status
            state.order_data.payment_status = response.payment_status
        },
    },
    actions: {
        async fetchOrderData({state, commit}, id){
            try{
                const response = await api.get(process.env.VUE_APP_BASE_URL+'order/'+id)
                commit('setOrderData', response.data)
            } catch (e){
                console.log("Ошибка получения данных с сервера о заказе")
            }
        },
        async fetchPaymentStatus({state, commit}, id){
            try{
                const response = await api.get(process.env.VUE_APP_BASE_URL+'checkPaymentStatus/Order/'+id)
                commit('setOrderPaymentStatus', response.data)
            } catch (e){
                console.log("Ошибка получения данных с сервера о статусе оплаты")
            }
        },
        async getPaymentUrl({state, commit}, order_id){
            try{
                const response = await api.get(process.env.VUE_APP_BASE_URL+'order/'+order_id+'/toPayment')
                return response.data
            } catch (e){
				console.log(e);
                console.log("Ошибка получения ссылки на оплату с сервера ")
            }
        },
    },
    namespaced: true
}