<template>
<default-layout>
    <template #content>
        <page-title title="Новости" />
        <div v-if="loaded && post_categories" class="container mb-4">
            <div class="row gx-3">
                <div class="col-auto mb-3 mb-md-0">
                    <a @click.prevent="active_category = 0" href="#" class="btn btn-white btn-rounded px-3 px-md-4" :class="active_category == 0 ? 'active' : ''">Все</a>
                </div>
                <div class="col-auto mb-3 mb-md-0" v-for="cat in post_categories" :key="cat">
                    <a @click.prevent="active_category = cat" href="#" class="btn btn-white btn-rounded px-3 px-md-4" :class="active_category == cat ? 'active' : ''">{{cat}}</a>
                </div>
            </div>
        </div>

        <div v-if="loaded" class="page_section">
            <div class="container">
                <div v-if="paginatedFilteredRows.length > 0" class="row g-4 mb-5">
                    <div class="col-md-6">
                        <NewsCard :post="paginatedFilteredRows[0]" />
                    </div>
                    <div class="col-md-6 d-flex flex-column justify-content-between">
                        <div class="row">
                            <div v-if="paginatedFilteredRows[1]" class="col-12 py-2 py-md-0 pb-md-4">
                                <NewsCard 
                                :post="paginatedFilteredRows[1]"
                                :class="'card_sm_height'" />
                            </div>
                            <div v-if="paginatedFilteredRows[2]" class="col-12">
                                <NewsCard 
                                :post="paginatedFilteredRows[2]"
                                :class="'card_sm_height'" />
                            </div>
                        </div>
                    </div>

                    <template v-for="(item, i) in paginatedFilteredRows" :key="i">
                        <div v-if="i > 2" class="col-md-6">
                            <NewsCard :post="item"
                             :class="'card_sm_height'" />
                        </div>
                    </template>
                </div>

                <PageAlertBox v-else :alert="{class: 'alert-warning', title: 'Записей по вашему запросу не найдено', text: 'Возможно, скоро они появятся'}"/>

                <PaginationBox class="mt-4" :currentPage="currentPage" :pageSize="pageSize" :itemsLength="filteredPosts.length" @changepage="changeCurrentPage"/>

            </div>
        </div>

    </template>
</default-layout>
</template>

<script>
import PageAlertBox from '../components/PageAlertBox.vue'
import NewsCard from '../components/cards/NewsCard.vue'
import PaginationBox from '../components/PaginationBox.vue'
import PageTitle from '../components/PageTitle.vue'
import DefaultLayout from '../layouts/DefaultLayout.vue'
import {
    mapGetters, mapActions
} from "vuex";
import moment from 'moment'
import setMeta from "@/views/seoMeta"
export default {
    components: {
        DefaultLayout,
        PageTitle,
        PaginationBox, NewsCard, PageAlertBox
    },
    data() {
        return {
            posts: [],
            post_categories: [],
            active_category: 0,
            pageSize: 15,
            currentPage: 1,
            loaded: false,
        }
    },
    async mounted() {
        setMeta(
            'Новости | Swimtech.ru', 
            null, 
            null,
            false
        )
        await this.fetchPosts()
        this.posts = this.getPosts.posts
        this.post_categories = this.getPosts.categories
        this.loaded = true
    },
    methods: {
        ...mapActions({
            fetchPosts: "news/fetchPosts",
        }),
        changeCurrentPage(page){
            window.scrollTo(0,0);
            this.currentPage = page
            return true
        },
    },
    computed: {
        ...mapGetters({
            getPosts: "news/getPosts",
        }),
        sortedPosts(){
            let ordered_posts = this.posts.filter(item => item.order > 0).sort((a, b) => {
                if(a.order === b.order) return moment(b.date, 'DD-MM-YYYY') - moment(a.date, 'DD-MM-YYYY')
                return a.order - b.order
            })

            let date_posts = this.posts.filter(item =>
                !ordered_posts.includes(item)
            ).sort((a, b) => {
                return moment(b.date, 'DD-MM-YYYY') - moment(a.date, 'DD-MM-YYYY') 
            })

            return ordered_posts.concat(date_posts)
        },
        filteredPosts(){
            return this.sortedPosts.filter((item, i) => {
                if(this.active_category == 0 || this.active_category == item.category) {
                    return true;
                }
                return false
            })
        },
        paginatedFilteredRows: function (){
            return this.filteredPosts.filter((row, index) => {
                let start = (this.currentPage-1)*this.pageSize;
                let end = this.currentPage*this.pageSize;
                if(index >= start && index < end) return true;
            });
        },
    }
}
</script>

<style>

</style>
