<template>
	<with-sidebar-layout>
		<template #head>
			<page-title title="Форма заявки" :breadcrumbs="breadcrumbs" />
			<PageAlertBox
				v-if="postData.alert_message"
				:alert="{
					class: 'alert-warning',
					title: 'Внимание!',
					text: postData.alert_message,
				}" />
			<PageAlertBox
				v-if="hasErrors() && show_errors"
				:alert="{
					class: 'alert-danger',
					title: 'Форма содержит ошибки',
					text: 'Пожалуйста, проверьте правильность заполнения полей',
				}" />
		</template>
		<template #content>
			<FormSteps :steps="steps" :activeStep="activeStep" />

			<div class="form_step form_step_1" ref="form_step_1" v-if="activeStep === 1">
				<FormClubFieldgroup
					v-model:club_name="form_data.club_name"
					v-model:club_shortname="form_data.club_shortname"
					v-model:club_state="form_data.club_state"
					v-model:club_city="form_data.club_city"
					v-model:has_errors="club_has_errors"
					:show_errors="show_errors"
					@selectClub="selectClub" />

				<FormContactsFieldgroup
					v-model:agent_name="form_data.agent_name"
					v-model:agent_phone="form_data.agent_phone"
					v-model:agent_email="form_data.agent_email"
					v-model:has_errors="contacts_has_errors"
					:show_errors="show_errors" />

				<FormAthletesSet
					v-if="form_data.club_name"
					v-model="form_data.distances"
					v-model:has_errors="distances_has_errors"
					:required_documents="postData.athlete_documents"
					:allowed_distances="postData.distances"
					:disallowed_athletes="STAGE_ORDERS_ATHLETES"
					:age_limits="postData.age_limits"
					:athlete_limit="postData.athlete_limit"
					:agedata_value="postData.agedata_value"
					:event_stage_id="postData.id"
					:offline_request_text="postData.offline_request_text"
					:show_errors="show_errors"
					title="Добавьте спортсменов и дистанции"
					subtitle="Добавьте необходимое кол-во спортсменов, документы для подачи онлайн-заявки, а также дистанции и заявочное время. В случае, если в базе данных имеется информация о результатах в выбранной дистанции, она проставится автоматически" />
			</div>

			<div
				v-if="postData.relay_races && postData.relay_races.length && activeStep === 2"
				class="form_step">
				<FormRelayRacesSet
					v-model="form_data.relay_races"
					:items="postData.relay_races"
					:price="postData.relay_price" />
			</div>

			<div
				v-if="
					postData.extra_services && postData.extra_services.length && activeStep === 3
				"
				class="form_step">
				<FormExtraServicesSet
					v-model="form_data.extra_services"
					:items="postData.extra_services"
					:distances="form_data.distances" />
			</div>

			<div class="form_step" v-if="activeStep === 4">
				<FormCompetitionLastStep
					v-model:payers_name="form_data.payers_name"
					v-model:payers_phone="form_data.payers_phone"
					v-model:payers_email="form_data.payers_email"
					v-model:payment_method="form_data.payment_method"
					v-model:has_errors="last_step_errors"
					:formdata="form_data"
					:postdata="postData"
					:show_errors="show_errors"
					:already_paid="
						orderdata && orderdata.already_paid ? orderdata.already_paid : null
					"
					:hide_payment_methods="extraPay != null && extraPay <= 0"
					@sendOrder="sendOrder()" />
			</div>

			<div
				class="stepform_actions rounded mb-5"
				v-if="activeStep != lastStep && form_data.club_name">
				<div class="row g-3 justify-content-end mt-4 p-3 pt-0">
					<div class="col-md-4">
						<button
							@click.prevent="prevStep"
							v-if="activeStep > 1"
							class="btn btn-outline btn-outline-secondary btn-rounded w-100">
							Назад
						</button>
					</div>
					<div class="col-md-4"></div>
					<div class="col-md-4">
						<button @click.prevent="nextStep" class="btn btn-primary btn-rounded w-100">
							Далее
						</button>
					</div>
				</div>
			</div>

			<div class="content_box p-4 mb-4" v-if="activeStep == lastStep">
				<FormAcceptChecks
					v-model:accept_checks="accept_checks"
					v-model:activeStep="activeStep"
					@prevStep="prevStep"
					:show_errors="show_errors"
					:has_errors="hasErrors()"
					:hasLastErrors="hasLastErrors()"
					:first_status="postData.first_status"
					:hideDraftButton="!!orderdata.already_paid"
					:extra_pay="extraPay"
					@sendOrder="sendOrder()"
					@saveAsDraft="sendOrder('draft')" />
			</div>
		</template>

		<template #sidebar>
			<div class="sticky_box">
				<ExpiredBox
					v-if="form_data.expired_time"
					:time="form_data.expired_time"
					:bottom_text="
						orderdata.already_paid
							? 'В противном случае изменения не будут сохранены'
							: null
					"
					@time_end="formExpired()" />

				<button
					@click.prevent="storeQuietly"
					class="btn btn-primary btn-rounded w-100 mb-4"
					:disabled="!form_data.club_name || !hasDistances">
					Резервировать дистанции
				</button>

				<InstructionsBox
					:button_disabled="false"
					:button_text="orderdata.already_paid ? 'Отправить' : null"
					@saveAsDraft="orderdata.already_paid ? sendOrder() : sendOrder('draft')" />
			</div>
		</template>
	</with-sidebar-layout>
	<ShopOffcanvas
		v-if="postData.shop_products && postData.shop_products.length"
		:item="{
			title: postData.shop_title,
			subtitle: postData.shop_subtitle,
			items: postData.shop_products,
		}"
		:visible="shop_modal_visible"
		@hide="shop_modal_visible = false" />
</template>

<script>
import ShopOffcanvas from '../../components/offcanvases/ShopOffcanvas.vue'
import ExpiredBox from '../../components/forms/ExpiredBox'
import FormAcceptChecks from './FormAcceptChecks'
import FormExtraServicesSet from './FormExtraServicesSet'
import FormRelayRacesSet from './FormRelayRacesSet'
import FormAthletesSet from './FormAthletesSet'
import FormContactsFieldgroup from './FormContactsFieldgroup'
import FormClubFieldgroup from './FormClubFieldgroup'
import FormCompetitionLastStep from './FormCompetitionLastStep'
import FormSteps from '@/components/forms//FormSteps'
import PageAlertBox from '../../components/PageAlertBox.vue'
import PageTitle from '../../components/PageTitle.vue'
import InstructionsBox from '../../components/forms/InstructionsBox.vue'
import WithSidebarLayout from '@/layouts/WithSidebarLayout.vue'
import { useToast } from 'vue-toastification'
import moment from 'moment-timezone'
import hash from 'object-hash'
import calculateSumm from '@/helpers/calculateSumm'
import api from '@/store/api'
import { mapGetters } from 'vuex'
export default {
	props: {
		postData: {
			type: Object,
			default: {},
		},
		orderdata: {
			type: Object,
			default: {},
		},
	},
	data() {
		return {
			form_data: {
				order_id: null,
				form_id: null,
				type: 'competition',
				event_id: null,
				event_child_id: null,
				club_name: null,
				club_shortname: null,
				club_state: null,
				club_city: null,
				agent_name: null,
				agent_phone: null,
				agent_email: null,
				entry_fee: null,
				payers_name: null,
				payers_phone: null,
				payers_email: null,
				payment_method: 5,
				distances: [
					{
						distances: [
							{
								name: null,
								key: 0,
								id: null,
								entrytime: null,
								system_entrytime: false,
								is_payed: 0,
							},
						],
						athlete: null,
						athlete_id: null,
						online_request: false,
						athlete_documents: [],
					},
				],
				relay_races: [],
				extra_services: [],
				expired_time: null,
			},
			used_athletes: [],
			used_distances: [],
			activeStep: 1,
			club_has_errors: false,
			contacts_has_errors: false,
			distances_has_errors: false,
			last_step_errors: false,
			accept_checks: [],
			show_errors: false,
			shop_modal_visible: false,
		}
	},
	async mounted() {
		if (this.postData && !_.isEmpty(this.postData)) {
			this.setFormData()
			this.setOtherData()
		}
		if (this.orderdata && !_.isEmpty(this.orderdata)) {
			this.transformOrderData()
			//this.addDistancesPrice()
		}
		if (this.USERDATA && !_.isEmpty(this.USERDATA)) {
			this.setFormId()
		}
		this.setLoadingToDistances()
		this.$store.dispatch('events/fetchStageOrdersAthletes', this.postData.id)
	},
	computed: {
		...mapGetters({
			USERDATA: 'profile/getUserData',
			STAGE_ORDERS_ATHLETES: 'events/getStageOrdersAthletes',
		}),
		hasDistances() {
			let result = false
			this.form_data.distances.forEach((item) => {
				if (item.distances.find((el) => el.id > 0)) {
					result = true
				}
			})
			return result
		},
		formDataWatched() {
			return _.cloneDeep(this.form_data.distances)
		},
		breadcrumbs() {
			return [
				{
					title: this.postData.name,
					link: '/stage/' + this.postData.id,
				},
			]
		},
		steps() {
			let steps = [
				{
					id: 1,
					name: 'Формирование заявки',
				},
				{
					id: 4,
					name: 'Подтверждение',
					isSuccess: false,
				},
			]
			if (this.postData.relay_races && this.postData.relay_races.length) {
				steps.push({
					id: 2,
					name: 'Участие в эстафетах',
				})
			}
			if (this.postData.extra_services && this.postData.extra_services.length) {
				steps.push({
					id: 3,
					name: 'Дополнительные услуги',
				})
			}

			return steps.sort((a, b) => a.id - b.id)
		},
		lastStep() {
			return this.steps[this.steps.length - 1].id
		},
		summ() {
			return calculateSumm(this.form_data)
		},
		extraPay() {
			if (!this.orderdata || !this.orderdata.already_paid) return null
			return this.summ - this.orderdata.already_paid
		},
	},
	watch: {
		USERDATA: {
			handler() {
				this.setFormId()
			},
			deep: true,
		},
		activeStep(val, oldVal) {
			if (oldVal == 1) {
				this.storeQuietly()
			}
			if (val == 4) {
				this.shop_modal_visible = true
			}
		},
		formDataWatched: {
			handler(newVal, oldVal) {
				if (this.getDifference(oldVal, newVal).length) {
					this.form_data.extra_services = []
					this.addDistancesPrice()
				}
			},
			deep: true,
		},
	},
	methods: {
		addDistancesPrice() {
			this.form_data.distances = this.form_data.distances.map((distance_obj) => {
				distance_obj.distances = distance_obj.distances.map((distance) => {
					if (distance.id && distance.distance) {
						if (distance_obj.distances.length > 1) {
							distance.count = 'many'
						} else {
							distance.count = 'one'
						}

						distance.price = parseInt(
							this.postData.distance_prices[distance.distance][distance.count]
						)
					}
					return distance
				})
				return distance_obj
			})
		},
		getDifference(object, base) {
			function changes(object, base) {
				return _.transform(object, function (result, value, key) {
					if (!_.isEqual(value, base[key])) {
						result[key] =
							_.isObject(value) && _.isObject(base[key])
								? changes(value, base[key])
								: value
					}
				})
			}
			return changes(object, base)
		},
		selectClub(selected_club) {
			if (selected_club.agent_name) this.form_data.agent_name = selected_club.agent_name
			if (selected_club.agent_phone) this.form_data.agent_phone = selected_club.agent_phone
			if (selected_club.agent_email) this.form_data.agent_email = selected_club.agent_email
		},
		setTimer() {
			if (!this.form_data.expired_time && this.postData.reservation_time) {
				this.form_data.expired_time = moment()
					.add(this.postData.reservation_time, 'minutes')
					.format()
			}
		},
		/* async addDistance(id, i, selected_athletes) {
			if (!this.form_data.expired_time && this.postData.reservation_time) {
				this.form_data.expired_time = moment()
					.add(this.postData.reservation_time, 'minutes')
					.format()
			}
			this.used_distances = []
			let age_limit_data = []
			this.form_data.distances.forEach((item) => {
				if (item.athlete) {
					age_limit_data.push({
						event_stage_id: this.postData.id,
						id: item.athlete.id,
						gender: item.athlete.gender,
						birthyear: item.athlete.birthdate.split('/')[2],
					})
				}
				item.distances.forEach((el) => {
					let index = this.used_distances.findIndex((item) => item.id === el.id)
					if (index > -1) {
						this.used_distances[index].quantity++
					} else {
						this.used_distances.push({ id: el.id, quantity: 1 })
					}
				})
			})

			let data = {
				form_id: this.form_data.form_id,
				age_limit_data: age_limit_data,
				distances: this.used_distances,
			}
			this.form_data.distances[i].loading = false
			await this.postDistanceReserved(data, i)
		}, */
		/* async postDistanceReserved(data, i) {
			await api
				.post(
					`${process.env.VUE_APP_BASE_URL}event-stage/${this.postData.id}/bookDistances`,
					data
				)
				.then((response) => {
					if (response.data && response.data.success) {
						this.form_data.distances[i].loading = false
					} else {
						this.form_data.distances[i].loading = false

						this.$swal(response.data.message, '', 'error')
					}
				})
				.catch((error) => {
					if (error.response && error.response.data.message) {
						this.$swal(error.response.data.message, '', 'error')
					}
				})
		}, */
		formExpired() {
			this.$swal(
				'Время бронирования истекло',
				'Неоплаченные дистанции будут удалены из заявки',
				'warning'
			)
			this.$router.back()
			return
		},
		setFormData() {
			this.form_data.event_id = this.postData.event_id
			this.form_data.event_child_id = this.postData.id
			this.form_data.entry_fee = this.postData.entry_fee
		},
		setOtherData() {
			this.setAcceptCheckboxes()
		},
		setAcceptCheckboxes() {
			if (this.postData && this.postData.accept_checkboxes) {
				this.accept_checks = this.postData.accept_checkboxes.map((obj) => ({
					...obj,
					check: false,
				}))
			}
		},
		setLoadingToDistances() {
			this.form_data.distances = this.form_data.distances.map((obj) => ({
				...obj,
				loading: false,
			}))
		},
		setFormId() {
			if (!this.form_data.form_id) {
				this.form_data.form_id = hash({
					client_id: this.USERDATA.id,
					event_id: this.postData.id,
					datetime: moment().format(),
				})
			}
		},
		showToast(text) {
			const toast = useToast()
			toast.info(text)
		},
		prevStep() {
			let current_step_index = this.steps.findIndex((item) => item.id == this.activeStep)
			this.activeStep = this.steps[current_step_index - 1]?.id ?? this.steps[0].id
		},
		nextStep() {
			if (this.hasErrors()) {
				this.show_errors = true
				this.$nextTick(() => {
					this.$el.querySelector('.invalid').focus()
				})
			} else {
				let current_step_index = this.steps.findIndex((item) => item.id == this.activeStep)
				this.activeStep = this.steps[current_step_index + 1]?.id ?? this.steps[0].id
			}
		},
		hasErrors() {
			if (this.club_has_errors || this.contacts_has_errors || this.distances_has_errors) {
				return true
			} else {
				return false
			}
		},
		hasLastErrors() {
			if (
				this.last_step_errors ||
				this.accept_checks.find((item) => item.check == false)
			) {
				return true
			} else {
				return false
			}
		},
		sendOrder(status) {
			if (this.hasLastErrors() && status != 'draft') {
				this.show_errors = true
				this.activeStep = this.lastStep
				this.$nextTick(() => {
					document.querySelector('.invalid').focus()
				})
			} else {
				if (
					status != 'draft' &&
					(this.extraPay == null || this.extraPay > 0) &&
					this.postData.first_status == 'pending_payment'
				) {
					this.$swal({
						title: 'Перенаправление на форму оплаты',
						text: 'Пожалуйста, не закрывайте страницу',
						icon: 'info',
						allowOutsideClick: false,
						allowEnterKey: false,
						allowEnterKey: false,
						showConfirmButton: false,
						didOpen: () => {
							this.$swal.showLoading()
						},
					})
				}
				this.storeOrder(status ? status : this.postData.first_status)
			}
		},

		storeOrder(status = 'draft') {
			this.form_data.status = status
			api
				.post(process.env.VUE_APP_BASE_URL + 'order/create', this.form_data)
				.then((res) => {
					if (res.data && res.data.success) {
						if (status == 'draft') {
							this.showToast('Заказ сохранен как черновик')
							this.$router.push({ name: 'profile' })
						} else if (
							(status == 'pending_payment' || this.extraPay > 0) &&
							res.data.url
						) {
							window.location.href = res.data.url
						} else {
							this.showToast('Заказ отправлен')
							this.$router.push({ name: 'profile' })
						}
					} else {
						this.$swal.close()
						console.log(res.data)
						this.$swal(
							'Возникла неизвестная ошибка.',
							'Заявка уже создана, для повторной попытки отправки перейдите к ее редактированию',
							'danger'
						)
						this.$router.push({ name: 'profile' })
					}
				})
				.catch((error) => {
					this.$swal.close()
					console.log('Catch', error, error.response)
					if (error.response && error.response.data.message) {
						this.$swal(error.response.data.message, '', 'danger')
					}
					this.$router.push({ name: 'profile' })
				})
		},
		async storeQuietly() {
			this.form_data.status = 'draft'
			api
				.post(process.env.VUE_APP_BASE_URL + 'order/create', this.form_data)
				.then((res) => {
					if (res.data && res.data.success) {
						this.form_data.id = res.data.id
						this.showToast('Дистанции успешно зарезервированы')

						if (res.data.prohibited_distances && res.data.prohibited_distances.length) {
							console.log(res.data.prohibited_distances)
							let deleted_distances_count = this.deteleProhibitedDistances(
								res.data.prohibited_distances
							)
							if (deleted_distances_count > 0) {
								this.showToast(
									'Некоторые незарезервированные дистанции были удалены из вашего заказа'
								)
							}
						}

						this.setTimer()
					} else {
						console.log(res.data)
						this.$swal(
							'Возникла неизвестная ошибка.',
							'Пожалуйста, попробуйте еще раз',
							'error'
						)
					}
				})
				.catch((error) => {
					console.log('Catch', error, error.response)
					if (error.response && error.response.data.message) {
						this.$swal(error.response.data.message, '', 'danger')
					}
				})
		},
		deteleProhibitedDistances(idsToRemove) {
			// Копируем массив output для избегания мутации данных в data()
			const filteredOutput = JSON.parse(JSON.stringify(this.form_data.distances))
			let count = 0
			filteredOutput.forEach((item) => {
				item.distances = item.distances.filter((distance) => {
					if (idsToRemove.includes(distance.id) && !distance?.is_payed) {
						count++
						return false
					}
					return true
				})
			})
			if (count > 0) {
				this.form_data.distances = filteredOutput.filter(
					(item) => item.distances.length > 0
				)
			}
			return count
		},
		transformOrderData() {
			if (this.orderdata) {
				this.form_data.id = this.orderdata.id
				this.form_data.type = this.orderdata.type
				this.form_data.event_id = this.orderdata.event_id
				this.form_data.event_child_id = this.orderdata.event_stage_id
				this.form_data.club_name = this.orderdata.club_name
				this.form_data.club_shortname = this.orderdata.club_shortname
				this.form_data.club_state = this.orderdata.club_state
				this.form_data.club_city = this.orderdata.club_city
				this.form_data.agent_name = this.orderdata.agent_name
				this.form_data.agent_phone = this.orderdata.agent_phone
				this.form_data.agent_email = this.orderdata.agent_email

				if (this.orderdata.form_id) {
					this.form_data.form_id = this.orderdata.form_id
				}
				if (this.orderdata.expiration_time) {
					this.form_data.expired_time = this.orderdata.expiration_time
				}

				if (this.orderdata.items && this.orderdata.items.length > 0) {
					this.form_data.distances = this.orderdata.items.filter(
						(item) => item.type == 'distance'
					)
					this.form_data.relay_races = this.orderdata.items.filter(
						(item) => item.type == 'relay_race'
					)

					/* let distances_array = []
					let relay_races_array = []
					this.orderdata.items.forEach((item, i) => {
						//console.log(distances_array)
						if (item.type === 'distance') {
							let index = distances_array.findIndex((el) => {
								return el.athlete_id === item.athlete_id
							})
							let distance_item = {
								entrytime: item.entrytime.split(':').slice(1).join(':'),
								id: item.distance_id,
								key: i,
								system_entrytime: item.system_entrytime,
								name: `${item.stroke_name}, ${item.age_name}`,
								distance: item.distance,
                                price: item.price,
                                count: item.count,
							}

							if (index != -1) {
								distances_array[index].distances.push(distance_item)
							} else {
								distances_array.push({
									athlete_id: item.athlete_id,
									online_request: !_.isEmpty(item.athlete_documents),
									athlete_documents: item.athlete_documents,
									distances: [distance_item],
								})
							}
						}
						if (item.type === 'relay_race') {
							relay_races_array.push({
								distance_id: item.distance_id,
								quantity: item.quantity,
								price: item.price,
							})
						}
					})
					if (!_.isEmpty(distances_array)) this.form_data.distances = distances_array
					if (!_.isEmpty(relay_races_array)) this.form_data.relay_races = relay_races_array */
				}

				if (this.orderdata.extra_services && this.orderdata.extra_services.length > 0) {
					setTimeout(() => {
						let extra_services_array = []
						this.orderdata.extra_services.forEach((item, i) => {
							let extra_service = {
								id: item.id,
								quantity: item.quantity,
								name: item.name,
								price: item.price,
								distances: [],
							}
							if (!_.isEmpty(item.athletes)) {
								item.athletes.forEach((athlete) => {
									let athlete_item = {
										athlete_id: athlete.athlete_id,
										distances: [],
									}
									if (!_.isEmpty(athlete.distances)) {
										athlete.distances.forEach((distance_id) => {
											athlete_item.distances.push(distance_id.id)
										})
									}
									extra_service.distances.push(athlete_item)
								})
							}
							extra_services_array.push(extra_service)
						})
						if (!_.isEmpty(extra_services_array))
							this.form_data.extra_services = extra_services_array
					}, 2000)
				}
			}
		},
	},
	components: {
		ExpiredBox,
		FormAcceptChecks,
		FormExtraServicesSet,
		FormRelayRacesSet,
		FormAthletesSet,
		FormContactsFieldgroup,
		FormClubFieldgroup,
		FormCompetitionLastStep,
		FormSteps,
		WithSidebarLayout,
		InstructionsBox,
		PageTitle,
		PageAlertBox,
		ShopOffcanvas,
	},
}
</script>

<style></style>
