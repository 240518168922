<template>
    <div class="upload_documents__item d-flex justify-content-between align-items-center py-3">
        <div class="upload_documents__item__label small me-3">
            <span class="d-block fw-bold" :class="modelValue ? 'text-success' : 'text-warning'">{{schema.name}}</span>
            <span v-if="schema.desc" class="d-block text-muted">{{schema.desc}}</span>
        </div>

        <div class="upload_documents__item__value d-flex justify-content-end align-items-center">
            <div class="text-end">
                <label>
                    <input @change="setFile" ref="fileInput" type="file" class="d-none" accept="image/*, .pdf" :multiple="schema.multiple">
                    <span v-if="!modelValue" class="btn btn-warning btn-sm">
                        Загрузить
                    </span>
                </label>
                <span v-if="modelValue" class="badge bg-success">Загружено</span>
                <p v-if="modelValue && modelValue.name" class="mt-1 mb-0 text-muted filename small">{{modelValue.name}}</p>
            </div>
            <vue-feather v-if="modelValue" @click="clearData" class="delete_icon text-muted ms-3" type="trash" size="16" />
        </div>
    </div>
</template>

<script>
export default {
    props: ["schema", 'modelValue'],
    emits: ['update:modelValue'],
    beforeUpdate(){
    },
    methods: {
        setFile(e){
            this.$emit('update:modelValue', e.target.files)
        },
        clearData(){
            this.$refs.fileInput.value = null
            this.$emit('update:modelValue', null)
        }
    }
};
</script>

<style scoped>
    .delete_icon{
        cursor: pointer;
    }
</style>