<template>
<div class="container" v-if="RESULTS.length">
    <div class="big_title d-block d-md-flex justify-content-between align-items-center">
        <h2 class="section_title mb-3 mb-md-0">Результаты</h2>
        <div class="carousel-nav mb-3">
            <button @click="swiperPrev()" class="carousel-control-prev me-3">
                <vue-feather type="arrow-left-circle"></vue-feather>
            </button>
            <button @click="swiperNext()" class="carousel-control-next">
                <vue-feather type="arrow-right-circle"></vue-feather>
            </button>
        </div>
    </div>
</div>

<div class="timeline_carousel">
    <div class="container">
        <swiper @slideChange="swiperSlideChange" :slidesPerView="4" :spaceBetween="30" ref="swiper_slider" :breakpoints="this.swiper_breakpoints">
            <swiper-slide v-for="(item, i) in sortedResults" :key="i" :class="this.active_item === i ? 'active' : ''">
                <TimelineCarouselItem :post="item" />
            </swiper-slide>
        </swiper>
        <div class="timeline_carousel__line"></div>
    </div>
</div>
</template>

<script>
import TimelineCarouselItem from './TimelineCarouselItem'
import {
    Swiper,
    SwiperSlide
} from "swiper/vue";
import "swiper/css";
import {
    mapGetters,
} from "vuex";
import moment from 'moment'
export default {
    components: {
        TimelineCarouselItem,
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            swiper: null,
            active_item: 0,
            swiper_breakpoints: {
                '0': {
                    slidesPerView: 1,
                },
                '550': {
                    slidesPerView: 2,
                },
                '1024': {
                    slidesPerView: 3,
                },
                '1200': {
                    slidesPerView: 4,
                },
            }
        }
    },
    mounted() {
        this.swiper = this.$refs.swiper_slider.$el.swiper
    },
    methods: {
        swiperSlideChange() {
            this.active_item = this.swiper.realIndex
        },
        swiperPrev() {
            this.swiper.slidePrev();
        },
        swiperNext() {
            this.swiper.slideNext();
        },
    },
    computed: {
        ...mapGetters({
            RESULTS: "home/results",
        }),
        sortedResults(){
            return this.RESULTS.sort((a, b) => moment(b.end_event_date, 'DD-MM-YYY HH:mm') - moment(a.end_event_date, 'DD-MM-YYY HH:mm'))
        }
    }
}
</script>

<style scoped>
    .swiper{
        overflow: visible;
    }

    @media(max-width: 768px){
        .swiper{
            overflow: hidden;
        }
    }

    .timeline_carousel{
        min-height: 180px;
    }

</style>
