<template>
<CDropdown>
    <CDropdownToggle class="user__link header_navbar__item d-flex align-items-center rounded">
        <div class="user__link__meta me-2 d-none d-lg-block">
            <p class="user__link__name mb-0 fw-bold text-end">{{user.name}}</p>
            <p class="user__link__status small text-muted mb-0 text-end">
                Пользователь
            </p>
        </div>
        <Avatar :src="user.avatar" :name="user.name" :class="'ms-1 user__link__img'"/>
    </CDropdownToggle>
    <CDropdownMenu>
        <CDropdownItem class="small">
          <router-link to="/profile"><vue-feather type="user" size="14" class="text-muted" /> Мой аккаунт</router-link>
        </CDropdownItem>
        <CDropdownItem class="small">
          <a @click.prevent="logout" href="#"><vue-feather type="log-out" size="14" class="text-muted"/> Выйти</a>
        </CDropdownItem>
    </CDropdownMenu>
</CDropdown>
</template>

<script>
import Avatar from '../UI/Avatar'
import {
    mapActions
} from "vuex";
import {
    CDropdown,
    CDropdownToggle,
    CDropdownMenu,
    CDropdownItem
} from '@coreui/vue'
export default {
    props: ['user'],
    components: {
    Avatar,
        CDropdown,
        CDropdownToggle,
        CDropdownMenu,
        CDropdownItem
    },
    methods: {
        logout(){
            localStorage.removeItem('access_token')
            this.clearUser()
            this.$router.push({name: 'login'})
        },
        ...mapActions({
            clearUser: "profile/clearUser",
        }),
    }
};
</script>

<style scoped>
button {
    font-family: inherit;
    padding: 0.2rem 0.5rem;
    font-weight: inherit;
    border-radius: 5px;
    box-shadow: none !important;
}

button:hover{
  background: #f0f1f2;
}

button:after {
    display: none;
}

.user__link__img {
    width: 38px;
}

a{
  text-decoration: none;
  color: #071031;
}

.dropdown-menu{
  min-width: 180px;
}
</style>
