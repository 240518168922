<template>
	<div
		@click.prevent="onClick(item.slug)"
		class="shop_product_card h-100 d-flex flex-column text-decoration-none"
		>
		<div class="title_row d-flex justify-content-between align-items-start p-3">
			<div class="product_title">
				<h3 class="fs-6 text-decorative text-uppercase text-dark fw-bold mb-1">{{ item.name }}</h3>
				<p class="text-muted mb-0">{{ item.category_name }}</p>
			</div>
			<div
				v-if="item.discount && item.discount > 0"
				class="product_discount text-white text-decorative fw-bold bg-danger py-1 px-2 ms-3 mr-n3">
				-{{ item.discount }}%
			</div>
		</div>

		<div class="product_card_image position-relative overflow-hidden">
			<div class="ratio ratio-1x1">
				<img :src="item.thumbnail" :class="!inStock ? 'grayscale' : ''"/>
			</div>
			<template v-if="!isMobile">
				<button v-if="!item.is_variable && inStock" @click.prevent.stop="onCart" class="btn btn-primary position-absolute top-50 start-50 translate-middle">
					<vue-feather :type="inCart ? 'plus' : 'shopping-cart'" class="me-2" />
					{{ inCart ? 'Добавить еще' : 'В корзину'}}
				</button>
				<button v-if="item.is_variable && inStock" @click.prevent.stop="onProduct" class="btn btn-primary position-absolute top-50 start-50 translate-middle">
					<vue-feather type="grid" class="me-2" />
					Выбрать вариант
				</button>
			</template>
			<div v-if="!inStock" class="bg-danger p-3 text-decorative text-uppercase text-white fw-bold position-absolute top-50 start-50 translate-middle">Нет в наличии</div>
		</div>

		<div class="price_row p-3 pt-2 mt-auto">
			<p v-if="item.old_price" class="price_old mb-none text-muted small mb-0">
				<del>{{ item.old_price }} ₽</del>
			</p>
			<p class="price_current mb-0 text-primary text-decorative fw-bold fs-5">
				{{ item.min_price }} {{ item.max_price != item.min_price ? '- ' + item.max_price : '' }} ₽
			</p>

            <div v-if="isMobile" class="mob_button_cont mt-3">
                <button v-if="!item.is_variable" @click.prevent.stop="onCart" class="btn btn-primary rounded-0 w-100">
                    <vue-feather :type="inCart ? 'plus' : 'shopping-cart'" class="me-2" />
                    {{ inCart ? 'Добавить еще' : 'В корзину'}}
                </button>
                <button v-else @click.prevent.stop="onProduct" class="btn btn-primary rounded-0 w-100">
                    <vue-feather type="grid" class="me-2" />
                    Выбрать вариант
                </button>
            </div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		item: {
			type: Object,
			required: true,
		},
		disable_link: {
			type: Boolean,
			default: false
		}
	},
    computed: {
        isMobile(){
            return window.innerWidth < 768
        },
		CART(){
			return this.$store.getters['shop/CART']
		},
		inCart(){
			return !!this.CART.find(cart_item => cart_item.id == this.item.id)
		},
		inStock(){
			let inStock = false;
			if(this.item.is_variable){
				this.item.variations.forEach(el => {
					if(el.quantity > 0) inStock = true
				})
			} else {
				inStock = !!this.item.quantity
			}

			return inStock
		},
    },
    methods: {
		onClick(slug){
			if(this.disable_link || !this.inStock) return
			this.$router.push({ name: 'product_page', params: { product: slug } })
		},
        onCart(){
			let cart_item = this.item
			cart_item.quantity = 1
			this.$store.commit('shop/addToCart', cart_item)
			document.querySelector('#btn__shop_cart').click()
        },
        onProduct(){
            this.$router.push({ name: 'product_page', params: { product: this.item.slug } })
        }
    }
}
</script>

<style>
.shop_product_card {
	background: #fff;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
	transition: all 0.4s;
	cursor: pointer;
}

.shop_product_card .product_card_image img {
	transition: all 0.4s;
	transform: scale(1);
}

.shop_product_card:hover {
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.shop_product_card:hover .product_card_image img {
	transform: scale(1.05);
    opacity: 0.3;
}

.product_card_image .btn{
    width: calc(100% - 40px);
    display: none;
    transition: all .4s;
    border-radius: 0;
}

.shop_product_card:hover .product_card_image .btn{
    display: block;
}

.grayscale{
	filter: grayscale(100%);
}
</style>
