<template>
	<auth-layout>
		<div class="row gx-0 min-vh-100">
			<div class="auth_background col-lg-8 d-none d-lg-block"></div>
			<div class="auth_content col-lg-4 d-flex align-items-center justify-content-center">
				<div
					class="auth_container d-flex flex-column align-items-center justify-content-center p-5 w-100">
					<router-link to="/">
						<img class="auth_logo mb-3" src="/assets/img/vfp-logo.svg" alt="Академия Спорта" />
					</router-link>
					<p class="section_title_medium mb-2">Добро пожаловать</p>
					<p class="text-muted">В учетную запись swimtech.ru</p>
					<form @submit.prevent="login()" class="auth_form w-100">
						<label class="mb-3 d-block">
							<span class="fw-bold">Email <span class="text-danger">*</span></span>
							<input
								v-model="email"
								type="email"
								class="form-control"
								placeholder="example@gmail.com"
								autocomplete="on"
								required />
						</label>

						<label class="mb-3 d-block">
							<div class="d-flex justify-content-between">
								<span class="fw-bold">Пароль <span class="text-danger">*</span></span>
								<router-link
									to="/auth/forgot-password"
									class="text-decoration-none text-mute"
									>Забыли пароль?</router-link
								>
							</div>

							<PasswordInput v-model="password" />
						</label>

						<button class="btn btn-primary btn-rounded w-100 mb-3">Войти</button>

						<div v-if="error_message" class="alert alert-danger text-danger" role="alert">
							{{ error_message }}
						</div>
					</form>
					<div class="auth_other">
						<span class="text-muted me-3">Еще не зарегистрированы?</span>
						<router-link to="/auth/registration" class="text-decoration-none"
							>Регистрация</router-link
						>
					</div>
				</div>
			</div>
		</div>
	</auth-layout>
</template>

<script>
import PasswordInput from '../../components/UI/PasswordInput.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'
import axios from 'axios'
export default {
	components: {
		AuthLayout,
		PasswordInput,
	},
	data() {
		return {
			email: null,
			password: null,
			error_message: null,
		}
	},
	async created() {
		await this.emailVerify()
	},
    mounted(){
        if(this.$route.params.email){
            this.email = this.$route.params.email
        }
    },
	methods: {
		async emailVerify() {
			if (this.$route.name === 'email_verify' && this.$route.query.verify_url) {
				axios
					.get(this.$route.query.verify_url)
					.then((res) => {
						this.error_message = null
						localStorage.setItem('access_token', res.data.token)
						this.$router.push({ name: 'profile' })
					})
					.catch((error) => {
						this.error_message = error.response.data.message
					})
			}
		},
		async login() {
			axios
				.post(
					process.env.VUE_APP_BASE_URL + 'login',
					{
						email: this.email,
						password: this.password,
					},
					{
						headers: {
							'Content-Type': 'application/json',
						},
					}
				)
				.then((res) => {
					this.error_message = null
					localStorage.setItem('access_token', res.data.token)
					this.$router.push({ name: 'profile' })
				})
				.catch((error) => {
					this.error_message = error.response.data.message
				})
		},
	},
}
</script>

<style>
.auth_logo {
    width: 130px;
	max-width: 130px;
}
</style>
