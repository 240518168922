<template>
<COffcanvas placement="start" :visible="getOffcanvas('mobile_menu')" @hide="closeOffcanvas('mobile_menu')">
    <div class="offcanvas-header justify-content-end">
        <button @click="closeOffcanvas('mobile_menu')" type="button" class="btn-close text-reset"></button>
    </div>
    <div class="offcanvas-body">
        <nav class="mobile_menu">
            <ul>
                <li v-for="el in getMainMenu" :key="el.id" class="menu_item" :class="el.children.length ? 'menu_item--with_children' : ''">

                    <router-link v-if="el.link" :to="el.link" ><span @click="closeOffcanvas('mobile_menu')">{{ el.name }}</span></router-link>
                    <a v-if="el.external_link" :href="el.external_link" ><span>{{ el.name }}</span></a>

                    <span v-if="el.children.length" @click="el.isActive = !el.isActive" class="submenu_trigger">
                        <vue-feather type="chevron-down" size="16" />
                    </span>
                    <ul v-if="el.children.length && el.isActive" class="sub_menu">
                        <li v-for="child in el.children" :key="child.id" class="menu_item">
                            <router-link v-if="child.link" :to="child.link"><span @click="closeOffcanvas('mobile_menu')">{{ child.name }}</span></router-link>
                            <a v-if="child.external_link" :href="child.external_link"><span>{{ child.name }}</span></a>
                        </li>
                    </ul>
                </li>

            </ul>
        </nav>
    </div>
</COffcanvas>
<div></div>
</template>

<script>
import {
    mapGetters,
    mapMutations
} from "vuex";
import {
    COffcanvas
} from '@coreui/vue'
export default {
    data() {
        return {
            expanded_els: []
        }
    },
    components: {
        COffcanvas
    },
    computed: {
        ...mapGetters({
            getOffcanvas: "global/getOffcanvas",
            getMainMenu: "global/getMainMenu"
        }),
    },
    methods: {
        ...mapMutations({
            toggleOffcanvas: "global/toggleOffcanvas",
            closeOffcanvas: "global/closeOffcanvas",
        }),
    }
}
</script>

<style>
.mobile_menu ul {
    list-style: none;
    padding-left: 0;
}

.mobile_menu ul li {
    display: block;
    border-bottom: 1px solid #E9EBF1;
    position: relative;
    overflow: hidden;
}

.mobile_menu ul.sub_menu {
    transition: all .4s;
}

.mobile_menu li a {
    text-decoration: none;
    color: #071031;
    padding: 10px 0 10px 15px;
    display: block;
}

.mobile_menu>ul>li>a {
    font-weight: 700;
}

.mobile_menu ul.sub_menu li {
    background: #e9ebf1;
    border-bottom-color: #fff;
}

.mobile_menu ul.sub_menu li:last-child {
    border: none;
}

.mobile_menu .submenu_trigger {
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px 10px 9px;
    background: #f6f7fb;
}

.slideUp-enter-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
}

.slideUp-leave-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slideUp-enter-to,
.slide-leave {
    max-height: 100px;
    overflow: hidden;
}

.slideUp-enter,
.slide-leave-to {
    overflow: hidden;
    max-height: 0;
}
</style>
