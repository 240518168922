<template>
	<h2 v-if="title" class="section_title_medium">{{ title }}</h2>
	<p v-if="subtitle" class="text-muted">{{ subtitle }}</p>
	<div class="position-relative">
		<AthletFieldgroup
			v-for="(item, index) in theModel"
			:index="index"
			:key="index"
			:delete_btn="theModel.length > 1 ? true : false"
			:required_documents="required_documents"
			:allowed_distances="allowed_distances"
			:athletes="ATHLETES"
			:allAthletes="ALL_ATHLETES"
			:disallowed_athletes="disallowed_athletes"
			:event_stage_id="event_stage_id"
			:offline_request_text="offline_request_text"
			:used_athletes="selected_athletes"
			:age_limits="age_limits"
			:athlete_limit="athlete_limit"
            :agedata_value="agedata_value"
			:show_errors="show_errors"
			v-model="theModel[index]"
			v-model:has_errors="has_errors"
            :check_age_limit="true"
			@remove="removeAthleteFieldGroup"
			@addDistance="(id) => $emit('addDistance', id, index, selected_athletes)"
			@removeDistance="(id) => $emit('removeDistance', id)"
			@setAthlete="selectAthlete" />

		<div v-if="!is_loaded" class="loading_overlay">
			<CSpinner color="primary" />
		</div>
	</div>

	

	<button @click.prevent="addAthletFieldgroup" class="btn btn-secondary">
		<vue-feather type="plus-circle" /> Добавить спортсмена
	</button>
</template>

<script>
import AthletFieldgroup from '../../components/forms/AthletFieldgroup.vue'
import { mapGetters, mapActions } from 'vuex'
import { computed } from 'vue'
import { CSpinner } from '@coreui/vue'
export default {
	components: { AthletFieldgroup, CSpinner },
	props: {
		modelValue: {
			type: Array,
			default: () => [],
		},
		required_documents: {
			type: Array,
			default: [],
		},
		allowed_distances: {
			type: Array,
			default: [],
		},
		event_stage_id: {
			type: Number,
		},
		offline_request_text: {
			type: String,
		},
		has_errors: {
			type: Boolean,
			default: () => false,
		},
		show_errors: {
			type: Boolean,
			default: () => false,
		},
		title: {
			type: String,
			default: null,
		},
		subtitle: {
			type: String,
			default: null,
		},
        agedata_value: {
			type: String,
			default: null,
		},
		age_limits: {
			type: Array,
			default: [],
		},
		athlete_limit: {
			type: Number,
			default: null,
		},
		disallowed_athletes: {
			type: Array,
			default: [],
		},
	},
	data() {
		return {
			selected_athletes: [],
			used_athletes: [],
			is_loaded: false,
		}
	},
	computed: {
		...mapGetters({
			ATHLETES: 'profile/getAthletes',
			ALL_ATHLETES: 'athlets/getAthletesListNames',
			getAthleteByID: 'athlets/getAthleteByID',
		}),
		athleteIds(){
			return this.theModel.map(item => {
				return item.athlete_id
			})
		}
	},
	async mounted() {
		await this.fetchAthletes()
		await this.fetchAthletesByIds(this.athleteIds)
		//await this.fetchAthletesAll()
		this.insertAthletesInfo()
		this.is_loaded = true
	},
	methods: {
		...mapActions({
			fetchAthletes: 'profile/fetchAthletes',
			fetchAthletesByIds: 'athlets/fetchAthletesByIds',
		}),
		insertAthletesInfo() {
			this.theModel.forEach((item) => {
				if (item.athlete_id) {
					item.athlete = this.getAthleteByID(item.athlete_id)
				}
			})
		},
		addAthletFieldgroup() {
			if (this.allowed_distances) {
				this.theModel.push({
					distances: [
						{
							name: null,
							distance: null,
							id: null,
							entrytime: null,
							system_entrytime: false,
                            is_payed: 0,
						},
					],
					athlete_id: null,
					athlete_documents: [],
				})
			}
		},
		removeAthleteFieldGroup(e) {
			this.theModel.splice(e, 1)
			this.selected_athletes.splice(e, 1)
		},
		selectAthlete(id) {
			this.selected_athletes.push(id)
		},
	},
	setup(props, { emit }) {
		const theModel = computed({
			get: () => props.modelValue,
			set: (value) => emit('update:modelValue', value),
		})
		const has_errors = computed({
			get: () => props.has_errors,
			set: (value) => emit('update:has_errors', value),
		})
		return { theModel, has_errors }
	},
	emits: ['update:modelValue', 'update:has_errors', 'addDistance'],
}
</script>

<style scoped>
.loading_overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(255, 255, 255, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>