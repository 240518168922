<template>
	<div class="home_tabs" id="home_tabs">
		<div class="home_tabs__line"></div>
		<div class="home_tabs__content">
			<div class="container tab-content">
				<div class="row g-4">
					<div v-for="(event, i) in getAllEvents" :key="i" class="col-lg-4 col-md-6">
						<event-card :event="event" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import EventCard from '@/components/cards/EventCard.vue'
import { CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from '@coreui/vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
	components: {
		EventCard,
		CDropdown,
		CDropdownToggle,
		CDropdownMenu,
		CDropdownItem,
	},
	data() {
		return {
			active_tab: 99,
			active_tab_title: '',
			active_tab_subtitle: '',
		}
	},
	computed: {
		...mapGetters({
			eventTabs: 'home/eventTabs',
		}),
		getAllEvents() {
			let all_events = []
			this.eventTabs.forEach((element) => {
				all_events.push(...element.event_cards)
			})
			all_events = all_events.sort(
				(a, b) =>
					moment(a.start_event_date, 'DD-MM-YYYY HH:mm') -
					moment(b.start_event_date, 'DD-MM-YYYY HH:mm')
					
			)
			return all_events
		},
	},
}
</script>

<style scoped>
@media (max-width: 768px) {
	.home_tabs__header--mobile {
		display: block;
	}

	.home_tabs__header {
		display: none !important;
	}
}

@media (min-width: 768px) {
	.home_tabs__header {
		display: block;
	}

	.home_tabs__header--mobile {
		display: none;
	}
}

.home_tabs__header--mobile .btn-group > .home_tabs__item {
	padding: 15px;
	border: 1px solid #ebe9f1;
	margin-bottom: 15px;
	background: #fff;
	box-shadow: none !important;
}

.home_tabs__header--mobile .btn-group > .home_tabs__item:after {
	display: none;
}

.home_tabs__header--mobile .dropdown-item {
	padding: 0;
}

.home_tabs__header--mobile .home_tabs__item .subtitle {
	font-family: 'Nunito Sans', sans-serif;
	font-weight: 300;
}
</style>