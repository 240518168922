<template>
<default-layout>
    <template #content>
    <page-intro :title="postData.name" :image="postData.hero_img" :image_sm="postData.mobile_hero_img" :breadcrumbs="breadcrumbs" :button_text="status.name == 'registration_open' && !postData.disable_request ? 'Подать заявку' : null" @btnClick="toForm" />
    <page-navbar :items="page_navbar" />

    <div v-if="status" class="page_section mt-5">
        <div class="container">
            <div class="content_box p-4">
                <div class="row gy-4">
                    <div class="col-md-6">
                        <div class="badge text-decorative text-white text-uppercase small mb-3" :class="status.badge_class">{{status.text}}</div>

                        <div class="row g-2">
                            <div v-if="postData.location_city || postData.location_address" class="col-md-4">
                                <p class="text-muted mb-0">Место проведения:</p>
                                <p class="mb-0">{{location}}</p>
                            </div>
                            <div v-if="postData.dates" class="col-md-4">
                                <p class="text-muted mb-0">Даты:</p>
                                <p class="mb-0">{{postData.dates}}</p>
                            </div>
                            <div v-if="status.dates" class="col-md-4">
                                <p class="text-muted mb-0">Срок приема заявок:</p>
                                <p class="mb-0 fw-bold" :class="status.text_class">{{status.dates}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row g-4 justify-content-end">
                            <div class="col-md-6" v-if="postData.media_photo || postData.media_video">
                                <div class="file_box_sm p-3 bg-body rounded h-100 d-flex flex-column justify-content-center">
                                    <p v-if="status.name == 'stage_complited'" class="mb-2">Фото и видео уже опубликованы</p>
                                    <a href="#media_section" class="btn btn-white btn-rounded btn-sm w-100">
                                        <vue-feather type="image" class="text-primary"></vue-feather>Фото и видео
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-6" v-if="postData.result_link || postData.final_protocol_link || postData.lenex_file">
                                <div class="file_box_sm p-3 pb-0 bg-body rounded h-100 d-flex flex-column justify-content-center">
                                    <p v-if="status.name == 'stage_complited'" class="mb-2">Результаты этапа уже опубликованы</p>
                                    <a v-if="postData.result_link" :href="postData.result_link" target="_blank" rel="nofollow" class="btn btn-white btn-rounded btn-sm w-100 mb-3">
                                        <vue-feather type="list" class="text-primary"></vue-feather>Онлайн-результаты
                                    </a>
                                    <a v-if="postData.final_protocol_link" :href="postData.final_protocol_link" target="_blank" rel="nofollow" class="btn btn-white btn-rounded btn-sm w-100 mb-3">
                                        <vue-feather type="file-text" class="text-primary"></vue-feather>Итоговый протокол
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="postData.description" class="page_section" id="description_section">
        <div class="container">
            <PageLongText :text="postData.description" />
        </div>
    </div>

    <div v-if="postData.document_links && postData.document_links.length > 0" class="page_section" id="documents_section">
        <div class="container">
            <p class="section_title mb-4">Документы</p>
            <DocumentsRow :items="postData.document_links" />
        </div>
    </div>

    <div v-if="postData.timetable && postData.timetable.length > 0" class="page_section" id="timetable_section">
        <div class="container">
            <p class="section_title mb-4">Расписание</p>
            <TimetableRow :items="postData.timetable" />
        </div>
    </div>

    <div v-if="postData.location_name" class="page_section" id="location_section">
        <div class="container">
            <p class="section_title mb-4">Место проведения</p>
            <LocationInfo :coord="postData.location_coord" :name="postData.location_name" :desc="postData.location_desc" />
        </div>
    </div>

    <div v-if="postData.residences && postData.residences.length > 0" class="page_section" id="residences_section">
        <div class="container">
            <p class="section_title mb-4">Проживание</p>
            <div class="row">
                <div v-for="(item, i) in postData.residences" :key="i" class="col-md-4">
                    <Residence :item="item"/>
                </div>
            </div>
        </div>
    </div>

    <div v-if="postData.media_photo || postData.media_video" class="page_section" id="media_section">
        <div class="container">
            <p class="section_title mb-4">Медиа</p>
            <div class="row">
                <div class="col-md-6">
                    <MediaBox title="Фото" type="photo" :item="postData.media_photo" />
                </div>
                <div class="col-md-6">
                    <MediaBox title="Видео" type="video" :item="postData.media_video" />
                </div>
            </div>
        </div>
    </div>

    <template v-if="postData?.requisits" >
        <div class="container">
    <div class="requisits_box bg-secondary rounded px-4 py-5">
        <h2 class="text-warning fs-2 fw-bold text-uppercase text-decorative mb-4">Реквизиты</h2>
        <div class="row g-4 text-white">
            <div v-if="postData.requisits?.name" class="col-auto">
                <p class="text-muted mb-1">Компания:</p>
                <p class="mb-0">{{postData.requisits?.name}}</p>
            </div>
            <div v-if="postData.requisits?.address" class="col-auto">
                <p class="text-muted mb-1">Юридический адрес:</p>
                <p class="mb-0">{{postData.requisits?.address}}</p>
            </div>
            <div v-if="postData.requisits?.ogrn" class="col-auto">
                <p class="text-muted mb-1">ОГРН:</p>
                <p class="mb-0">{{postData.requisits?.ogrn}}</p>
            </div>
            <div v-if="postData.requisits?.inn" class="col-auto">
                <p class="text-muted mb-1">ИНН:</p>
                <p class="mb-0">{{postData.requisits?.inn}}</p>
            </div>
            <div v-if="postData.requisits?.phone" class="col-auto">
                <p class="text-muted mb-1">Телефон:</p>
                <p class="mb-0">{{postData.requisits?.phone}}</p>
            </div>
            <div v-if="postData.requisits?.email" class="col-auto">
                <p class="text-muted mb-1">Email:</p>
                <p class="mb-0">{{postData.requisits?.email}}</p>
            </div>
        </div>
    </div>
</div>
</template>

    </template>
</default-layout>
</template>

<script>
import DocumentsRow from '../components/UI/DocumentsRow'
import TimetableRow from '../components/UI/TimetableRow'
import LocationInfo from '../components/UI/LocationInfo'
import Residence from '../components/UI/Residence'
import MediaBox from '../components/MediaBox'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import PageIntro from '@/components/PageIntro.vue'
import PageNavbar from '@/components/PageNavbar.vue'
import PageLongText from '@/components/PageLongText'
import moment from "moment";
import setMeta from "@/views/seoMeta"
import {
    mapGetters, mapActions
} from "vuex";
export default {
    
    data() {
        return {
            status: {
                name: "closed",
                text: "",
                dates: null,
                badge_class: "bg-warning text-dark",
                text_class: "text-warning",
            },
        }
    },
    async mounted() {
        await this.fetchPost(this.$route.params.id)
        this.getStatus()
        setMeta(
            this.postData.seo_title, 
            this.postData.seo_description, 
            this.postData.thumbnail,
            this.postData.seo_robots
        )
    },
    methods: {
        ...mapActions({
            fetchPost: "events/fetchStage",
        }),
        toForm(){
            if(this.postData.external_url){
                window.location.href = this.postData.external_url;
                return;
            }
            this.$router.push({ name: 'event_form', query: {id: this.postData.id, type: 'competition'} })
        },
        getStatus() {
            let start_registration = null;
            let end_registration = null;
            let start_event = null;
            let end_event = null;
            let current_date = moment(this.postData.current_time, "DD-MM-YYYY hh:mm");

            if (
                this.postData.start_registration_date &&
                this.postData.end_registration_date
            ) {
                start_registration = moment(
                    this.postData.start_registration_date,
                    "DD-MM-YYYY hh:mm"
                );
                end_registration = moment(
                    this.postData.end_registration_date,
                    "DD-MM-YYYY hh:mm"
                );
                this.status.dates =
                    start_registration.format("DD.MM.YYYY") +
                    " - " +
                    end_registration.format("DD.MM.YYYY");
            } else {
                this.status.text = "Прием заявок пока закрыт";
                return;
            }

            if (current_date < end_registration) {
                if (current_date < start_registration) {
                    this.status.text = "Прием заявок пока закрыт";
                    this.status.name = "registration_closed_now";
                    return;
                }
                this.status.text = "Прием заявок уже идет";
                this.status.name = "registration_open";
                this.status.badge_class = "bg-success";
                this.status.text_class = "text-success";
                return;
            }

            if (this.postData.start_event_date && this.postData.end_event_date) {
                start_event = moment(
                    this.postData.start_event_date,
                    "DD-MM-YYYY hh:mm"
                );
                end_event = moment(
                    this.postData.end_event_date,
                    "DD-MM-YYYY hh:mm"
                );
            } else {
                this.status.text = "Прием заявок окончен";
                this.status.dates = 'Окончился '+end_registration.format("DD.MM.YYYY")
                this.status.name = "registration_end";
                this.status.badge_class = "bg-danger";
                this.status.text_class = "text-danger";
                return;
            }

            if (current_date < end_event) {
                this.status.dates = 'Окончился '+end_registration.format("DD.MM.YYYY")
                if (current_date < start_event) {
                    this.status.text = "Прием заявок окончен";
                    this.status.name = "event_not_now";
                    this.status.badge_class = "bg-danger";
                    this.status.text_class = "text-danger";
                    return;
                }
                this.status.text = "Этап уже идет";
                this.status.name = "stage_now";
                this.status.badge_class = "bg-success";
                this.status.text_class = "text-success";
                return;
            }

            this.status.text = "Этап завершен";
            this.status.dates = 'Окончился '+end_registration.format("DD.MM.YYYY")
            this.status.name = "stage_complited";
            this.status.badge_class = "bg-danger";
            this.status.text_class = "text-danger";
            return;
        },
    },
    computed: {
        ...mapGetters({
            postData: "events/getStagePost",
        }),
        breadcrumbs(){
            return [
                {
                    title: 'Мероприятия',
                    link: '/events/'
                },
                {
                    title: this.postData.parent_name,
                    link: '/events/'+this.postData.event_id
                }
            ]
        },
        page_navbar(){
            let page_navbar = [];
            if(this.postData.description){
                page_navbar.push({
                    id: 0,
                    title: "Основная информация",
                    link: "#description_desction"
                })
            }
            if(this.postData.document_links && this.postData.document_links.length > 0){
                page_navbar.push({
                    id: 1,
                    title: "Документы",
                    link: "#documents_section"
                })
            }
            if(this.postData.timetable && this.postData.timetable.length > 0){
                page_navbar.push({
                    id: 2,
                    title: "Расписание",
                    link: "#timetable_section"
                })
            }
            if(this.postData.location_name){
                page_navbar.push({
                    id: 3,
                    title: "Место проведения",
                    link: "#location_section"
                })
            }
            if(this.postData.residences && this.postData.residences.length > 0){
                page_navbar.push({
                    id: 4,
                    title: "Проживание",
                    link: "#residences_section"
                })
            }
            if(this.postData.media_photo || this.postData.media_video){
                page_navbar.push({
                    id: 5,
                    title: "Медиа",
                    link: "#media_section"
                })
            }
            return page_navbar
        },
        location(){
            let location = [];
            if(this.postData.location_city) location.push(this.postData.location_city)
            if(this.postData.location_name) location.push(this.postData.location_name)
            return location.join(', ')
        }
    },
    components: {
        DocumentsRow,
        TimetableRow,
        LocationInfo,
        Residence,
        MediaBox,
        DefaultLayout,
        PageIntro,
        PageNavbar,
        PageLongText,
    },
}
</script>

<style scoped>
    .file_box_sm{
        min-height: 100px;
    }
</style>
