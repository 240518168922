<template>
<auth-layout>
    <div class="row gx-0 min-vh-100">
        <div class="auth_background col-lg-8 d-none d-lg-block"></div>
        <div class="auth_content col-lg-4 d-flex align-items-center justify-content-center">
            <div class="auth_container d-flex flex-column align-items-center justify-content-center p-5 w-100">
                <router-link to="/">
                    <img class="auth_logo mb-3" src="/assets/img/vfp-logo.svg" alt="Академия Спорта" />
                </router-link>
                <p class="section_title_medium mb-2">Зарегистрируйтесь</p>
                <p class="text-muted">Для участия в мероприятиях</p>
                <form @submit.prevent="registration()" class="auth_form w-100">
                    <label class="mb-3 d-block">
                        <span class="fw-bold">Ваше ФИО <span class="text-danger">*</span></span>
                        <input v-model="name" type="text" class="form-control" placeholder="Иван Иванов" required />
                    </label>

                    <label class="mb-3 d-block">
                        <span class="fw-bold">Email <span class="text-danger">*</span></span>
                        <input v-model="email" type="email" class="form-control" placeholder="example@gmail.com" required />
                    </label>

                    <label class="mb-3 d-block">
                        <span class="fw-bold">Пароль <span class="text-danger">*</span></span>
                        <PasswordInput v-model="password" />
                    </label>

                    <div class="form-check mb-3">
                        <input class="form-check-input" type="checkbox" value="1" id="auth_accept" required />

                        <label class="form-check-label small" for="auth_accept">
                            Ознакомлен и согласен с  <router-link to="/page/10">правилами и условиями сервиса</router-link> и <router-link to="/page/8">политикой конфиденциальности</router-link>
                        </label>
                    </div>

                    <button class="btn btn-primary btn-rounded w-100 mb-3" :disabled="loading">
                        <vue-feather v-if="loading" type="settings" animation="spin" animation-speed="slow"></vue-feather>
                        <span v-else>Зарегистрироваться</span>
                    </button>
                    <div v-if="error_message" class="alert alert-danger text-danger" role="alert">{{error_message}}</div>
                </form>
                <div class="auth_other">
                    <span class="text-muted me-3">Уже есть аккаунт?</span>
                    <router-link to="/auth" class="text-decoration-none">Войти</router-link>
                </div>
            </div>
        </div>
    </div>
</auth-layout>
</template>

<script>
import PasswordInput from '../../components/UI/PasswordInput.vue'
import AuthLayout from '@/layouts/AuthLayout.vue';
import axios from "axios"
export default {
    components: {
        AuthLayout,
        PasswordInput
    },
    data(){
        return {
            name: null,
            email: null,
            password: null,
            error_message: null,
            loading: false,
        }
    },
    methods: {
        registration(){
            this.loading = true
            axios.post(process.env.VUE_APP_BASE_URL+'register', {
                name: this.name, 
                email: this.email, 
                password: this.password
            },{
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                if(res.data.success){
                    this.error_message = null
                    this.loading = false
                    localStorage.setItem('access_token', res.data.token)
					this.$router.push({ name: 'profile' })
                } else {
                    this.error_message = error.response.data.message
                }
                this.loading = false
            }).catch(error => {
                this.loading = false
                this.error_message = error.response.data.message
            })
        } 
    }
};
</script>

<style>
</style>
