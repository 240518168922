import api from "./api"

export const athletesStateModule = {
	state: () => ({
		athletes: [],
	}),
	getters: {
		getAthletes(state) {
			return state.athletes
		},
		getAthletesListNames(state) {
			let athletes = []
			for (let i = 0; i < state.athletes.length; i++) {
				athletes[i] = state.athletes[i]
				athletes[i].list_name =
					state.athletes[i].name + ' - ' + state.athletes[i].birthdate + ' г.р.'
			}
			return athletes
		},
		getAthleteByID: (state) => (id) => {
			return state.athletes.find((item) => item.id === id)
		},
	},
	mutations: {
		setAthletes(state, response) {
			state.athletes = response
		},
		changeAthlete(state, athlete) {
			let index = state.athletes.findIndex((item) => {
				return item.id === athlete.id
			})
			if (index > -1) {
				state.athletes[index] = athlete
			} else {
				state.athletes.push(athlete)
			}
		},
		removeAthlete(state, id) {
			let index = state.athletes.findIndex((item) => {
				return item.id === id
			})
			if (index > -1) {
				state.athletes.splice(index, 1)
			}
		},
	},
	actions: {
		async fetchAthletes({ state, commit }) {
			try {
				const response = await api.get(process.env.VUE_APP_BASE_URL + 'athletes/all')
				commit('setAthletes', response.data)
			} catch (e) {
				console.log('Ошибка получения данных с сервера о атлетах')
			}
		},
		async fetchAthletesByName({ state, commit }, search) {
			try {
				const response = await api.get(process.env.VUE_APP_BASE_URL + 'athletes/search', {
					params: {
					  search: search
					}
				  })
				  commit('setAthletes', response.data)
			} catch (e) {
				console.log('Ошибка получения данных с сервера о атлетах')
			}
		},
		async fetchAthletesByIds({ state, commit }, ids) {
			try {
				const response = await api.get(process.env.VUE_APP_BASE_URL + 'athletes/searchbyids', {
					params: {
					  ids: ids
					}
				  })
				  commit('setAthletes', response.data)
			} catch (e) {
				console.log('Ошибка получения данных с сервера о атлетах')
			}
		},
	},
	namespaced: true,
}