<template>
	<footer :style="'padding-top:' + FOOTER_PADDING_TOP + 'px; margin-top: -' + (FOOTER_PADDING_TOP - 30) + 'px;'">
		<div class="container">
			<div class="footer_top row mt-4 mb-5">
				<div class="col-lg-9">
                    <template v-if="REQUISITS">
					<!-- <FooterNav /> -->
                    <p v-if="REQUISITS?.name" class="mb-1 fw-bold text-muted">{{REQUISITS?.name}}</p>
                    <p v-if="REQUISITS?.address" class="mb-1 text-muted">{{REQUISITS?.address}}</p>
                    <p v-if="REQUISITS?.ogrn" class="mb-1 text-muted">ОГРН: {{REQUISITS?.ogrn}}</p>
                    <p v-if="REQUISITS?.inn" class="mb-1 text-muted">ИНН: {{REQUISITS?.inn}}</p>
                    <p v-if="REQUISITS?.phone" class="mb-1 text-muted">Телефон: {{REQUISITS?.phone}}</p>
                    <p v-if="REQUISITS?.email" class="mb-1 text-muted">Email: {{REQUISITS?.email}}</p>
                </template>
				</div>
				<div class="col-lg-3">
                    <img class="ms-auto" src="/assets/img/pay_systems.svg">
					<!-- <SocialLinks /> -->
				</div>
			</div>
			<div class="footer_bottom row py-4">
				<div class="col">
					<router-link to="/page/11">Условия оплаты и возврат</router-link>
					<router-link to="/page/8">Политика конфиденциальности</router-link>
					<router-link to="/page/10">Правила и условия сервиса</router-link>
				</div>
                <div class="col-md-3">
					<!-- <SocialLinks /> -->

				</div>
				<div class="col-auto">
					<p class="text-md-end mt-4 mt-md-0">© 2014-{{ year }} <!-- ЗАО "Академия Спорта" --></p>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
/* import SocialLinks from '../SocialLinks'
import FooterNav from './FooterNav' */
export default {
	components: {
		/* SocialLinks,
		FooterNav, */
	},
	computed: {
		FOOTER_PADDING_TOP(){
			return this.$store.getters['global/getFooterTopPadding']
		},
		REQUISITS(){
			return this.$store.getters['global/getRequisits']
		},
		year() {
			return new Date().getFullYear()
		},
		is_subscribed() {
			if (localStorage.getItem('is_subscribed')) {
				return localStorage.getItem('is_subscribed')
			} else {
				if (this.userData) {
					return this.userData.is_subscribed
				}
			}
			return
		},
		userData() {
			return this.$store.getters['profile/getUserData']
		},
		showPadding(){
			if(!this.is_subscribed && !this.$route.fullPath.includes('shop')) return true
			return false
		}
	},
}
</script>

<style>
footer.footer__without_subscribe_section {
	padding-top: 30px;
	margin-top: 0;
}
</style>
